import React from 'react';
import { SaveButton as RaSaveButton, SaveButtonProps } from 'react-admin';

const EmptyIcon = () => <div />;

/**
 * Save button without an icon and partially override styles.
 */
const SaveButton = (props: SaveButtonProps) => {
  return (
    <RaSaveButton
      fullWidth
      variant="contained"
      type="submit"
      icon={<EmptyIcon />}
      {...props}
    />
  );
};

export default SaveButton;
