import React from 'react';
import { useDataProvider, useNotify } from 'react-admin';
import { resources } from 'appConstants';
import { Document, Attachment } from 'types';

interface UseAttachmentsProps {
  record?: Document;
}

const useAttachments = ({ record }: UseAttachmentsProps) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const once = React.useRef(false);
  const version = React.useRef(0);
  const [attachments, setAttachments] = React.useState<Attachment[]>([]);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState();

  const refresh = React.useCallback(() => {
    if (!record) return;
    setLoading(true);
    dataProvider.getList<Attachment>(
      resources.ATTACHMENTS,
      {
        filter: {
          uuid: record.docid,
        },
        sort: {
          field: 'id',
          order: ''
        },
        pagination: {
          page: 1,
          perPage: 50,
        },
      })
      .then(({ data }) => {
        setAttachments(data);
        setLoading(false);
        version.current += 1;
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      })
  }, [dataProvider, record, setAttachments, setLoading, setError, version]);

  const download = React.useCallback((attachment: Attachment) => () => {
    dataProvider.getOne(resources.BINARY, { id: attachment.attid })
      .then(({ data }) => {
        const element = document.createElement('a');
        document.body.appendChild(element);
        element.setAttribute('target', 'blank');
        element.setAttribute('rel', 'noopener noreferrer');
        element.setAttribute('href', data.location);
        element.setAttribute('download', data.location);
        element.style.display = '';

        element.click();

        document.body.removeChild(element);
      })
      .catch(error => {
        notify(
          error.message
            ? error.message
            : 'Something goes wrong, please try again later.',
          'error',
        );
      })
  }, [dataProvider, notify]);

  React.useEffect(() => {
    if (!record || once.current) return;

    once.current = true;
    refresh();
  }, [record, refresh, once]);

  return {
    refresh,
    loading,
    error,
    attachments,
    download,
    version: version.current,
  }
}

export default useAttachments;
