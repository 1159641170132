import React from 'react';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Document } from 'types';
import LoadingWrapper from 'components/LoadingWrapper';
import useNotes from './useNotes';
import NoteForm from './NoteForm';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    height: '100%',
    overflowY: 'auto',
  },
  card: {
    flex: '0 0 auto',
    marginBottom: theme.spacing(1),
  }
}));

export interface NotesProps {
  record: Document
}

const Notes = ({ record }: NotesProps) => {
  const classes = useStyles();
  const { notes, loading, refresh } = useNotes({ record });
  return (
    <Grid
      xs
      item
      container
      direction="column"
      wrap="nowrap"
      className={classes.container}
    >
      <NoteForm record={record} onSuccess={refresh} />
      <LoadingWrapper loading={loading}>
        {notes.map((note, idx) => (
          <Card
            key={idx}
            className={classes.card}
          >
            <CardContent>
              <Typography>
                {note.notedata}
              </Typography>
            </CardContent>
          </Card>
        ))}
      </LoadingWrapper>
    </Grid>
  )
}

export default Notes;
