import { useLogin as useAdminLogin, useNotify, useSafeSetState } from 'react-admin';

interface UseLoginProp {
  redirectTo?: string;
}

const useLogin = <FormValues>({ redirectTo }: UseLoginProp) => {
  const [loading, setLoading] = useSafeSetState(false);
  const login = useAdminLogin();
  const notify = useNotify();
  
  const submit = (values: FormValues) => {
    setLoading(true);
    login(values, redirectTo)
      .then(() => {
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
        notify(
          typeof error === 'string'
          ? error
          : typeof error === 'undefined' || !error.message
            ? 'ra.auth.sign_in_error'
            : error.message,
          'warning',
          {
            _:
              typeof error === 'string'
              ? error
              : error && error.message
                ? error.message
                : undefined,
          }
        );
      });
  };
  
  return {
    submit,
    loading,
  }
}

export default useLogin;
