import { createTheme } from '@material-ui/core/styles';
import createPalette from '@material-ui/core/styles/createPalette';
import createTypography from '@material-ui/core/styles/createTypography';

const palette = createPalette({});
const typography = createTypography(palette, {
  fontFamily: 'Open Sans, Arial, Helvetica, sans-serif',
  fontSize: 14,
  htmlFontSize: 14,
});

const theme = createTheme({
  typography: {
    ...typography,
    body1: {
      ...typography.body1,
      fontSize: '1rem',
    },
    h6: {
      ...typography.h6,
      fontWeight: 700,
      fontSize: '1.285714285714286rem',
      marginBottom: '1rem',
    },
  },
  overrides: {
    MuiAppBar: {
      root: {
        background: 'linear-gradient(to right, #3a3633 93%, #2a2725 100%)',
      },
    },
    MuiIconButton: {
      root: {
        color: '#fff',
      },
      sizeSmall: {
        fontSize: 18,
      }
    },
    MuiSvgIcon: {
      root: {
        fontSize: 'inherit',
      },
    },
    // MuiOutlinedInput: {
    //   root: {
    //     minHeight: 52,
    //   },
    //   input: {
    //     paddingTop: 4,
    //     paddingBottom: 4,
    //   },
    // },
    // MuiButton: {
    //   contained: {
    //     boxShadow: '0 0 0 0 rgb(34 36 38 / 15%) inset',
    //   },
    //   containedPrimary: {
    //     backgroundColor: '#2185d0',
    //     '&:hover': {
    //       backgroundColor: '#1678c2',
    //     },
    //   },
    //   outlinedPrimary: {
    //     background: '#e0e1e2',
    //     border: 'none',
    //     borderRadius: 4,
    //     color: 'rgba(0,0,0,.6)',
    //     '&:hover': {
    //       backgroundColor: '#cacbcd',
    //       boxShadow:
    //         '0 0 0 1px transparent inset, 0 0 0 0 rgb(34 36 38 / 15%) inset',
    //       color: 'rgba(0,0,0,.8)',
    //       border: 'none',
    //     },
    //   },
    // },
    MuiInputBase: {
      root: {
        color: 'inherit',
      },
      input: {
        color: 'inherit',
        
      }
    },
    MuiFormControl: {
      fullWidth: {
        minWidth: 240,
      },
    },
    MuiInput: {
      underline: {
        '&:after': {
          borderBottomColor: '#fff',
        },
        '&:before': {
          borderBottomColor: '#fff !important',
        },
      }
    },
    MuiCardHeader: {
      root: {
        backgroundColor: '#3a3633',
        color: '#fff',
        padding: 8,
      },
      action: {
        alignSelf: 'center',
      },
      title: {
        fontSize: 18,
      }
    },
    MuiTableRow: {
      head: {
        backgroundColor: '#3a3633',
      },
    },
    MuiTableCell: {
      sizeSmall: {
        padding: '2px 24px',
        // fontSize: 12,
      },
      head: {
        backgroundColor: '#3a3633',
        color: '#fff',
        fontWeight: 700,
      }
    },
    MuiTableSortLabel: {
      root: {
        fontWeight: 'inherit',
      }
    },
    MuiTablePagination: {
      root: {
        overflow: 'hidden',
        width: '100%',
      },
      toolbar: {
        backgroundColor: '#3a3633',
        color: '#fff',
      },
    },
    MuiSelect: {
      icon: {
        color: 'inherit',
      }
    },
    // Notes: we use ts-ignore to here override admin styles
    // because in case of material ui theme those properties are not declared
    // @ts-ignore
    // RaSidebar: {
    //   drawerPaper: {
    //     maxWidth: 200,
    //   },
    //   paper: {
    //     background: 'linear-gradient(to right, #3a3633 93%, #2a2725 100%)',
    //   },
    // },
    RaMenuItemLink: {
      root: {
        color: '#c0bbb7',
      },
      active: {
        // background: 'rgba(0,0,0,.05)',
        color: '#fff',
      },
      icon: {
        color: 'inherit',
      },
    },
    RaPaginationActions: {
      actions: {
        color: '#fff',
      },
      button: {
        color: '#fff',
      },
      currentPageButton: {
        backgroundColor: '#fff',
        color: '#212121',
        '&:hover': {
          backgroundColor: '#efefef',
        }
      },
    },
    // RaSelectArrayInput: {
    //   root: {
    //     minWidth: 200,
    //   },
    // },
    // RaLogout: {
    //   menuItem: {
    //     color: 'rgba(0, 0, 0, 0.54)',
    //   },
    //   active: {
    //     background: 'rgba(0,0,0,.05)',
    //     color: '#fff',
    //   },
    //   icon: {
    //     color: 'inherit',
    //   },
    // },
    // RaAutocompleteArrayInput: {
    //   chipContainerOutlined: {
    //     margin: '2px 12px 2px 0',
    //   },
    // },
    // RaListToolbar: {
    //   toolbar: {
    //     marginTop: '1em',
    //     marginBottom: '1em',
    //     padding: 0,
    //     paddingLeft: '1em !important',
    //     paddingRight: '1em',
    //     border: '1px solid rgba(34, 36, 38, 0.15)',
    //     boxShadow: '0 1px 2px 0 rgb(34 36 38 / 15%)',
    //     borderRadius: 4,
    //     backgroundColor: '#fff',
    //   },
    //   actions: {
    //     minHeight: 30,
    //     paddingTop: 18,
    //     paddingBottom: 18,
    //   },
    // },
    RaLayout: {
      root: {
        backgroundColor: '#9e9e9e',
      },
    },
    RaDatagrid: {
      headerCell: {
        backgroundColor: '#212121',
        color: '#fff',
        fontSize: 12,
        borderLeft: '1px solid rgba(34,36,38,.1)',
        '&:first-child': {
          borderLeft: 'none',
          borderTopLeftRadius: 0,
        },
        '&:last-child': {
          borderTopRightRadius: 0,
        }
      },
      rowCell: {
        borderLeft: '1px solid rgba(34,36,38,.1)',
        fontSize: 12,
        '&:first-child': {
          borderLeft: 'none',
        },
      },
      headerRow: {
        backgroundColor: '#212121',
        lineHeight: 1.42857143,
        fontSize: 12,
      },
      row: {
        lineHeight: 1.42857143,
        fontSize: 12,
      },
      rowEven: {
        backgroundImage: 'none',
        backgroundColor: '#f9f9f9',
      },
    },
    // RaList: {
    //   content: {
    //     background: '#fff',
    //     border: '1px solid rgba(34,36,38,.15)',
    //     borderRadius: 4,
    //     textAlign: 'left',
    //     color: 'rgba(0,0,0,.87)',
    //     borderCollapse: 'separate',
    //     borderSpacing: 0,
    //     boxShadow: 'none',
    //   },
    // },
    // RaDatagridHeaderCell: {
    //   active: {
    //     color: '#fff !important',
    //   },
    //   icon: {
    //     color: 'inherit !important',
    //   },
    // },
    // RaPlaceholder: {
    //   root: {
    //     background: 'inherit',
    //   },
    // },
    // RaToolbar: {
    //   desktopToolbar: {
    //     background: 'inherit',
    //   },
    // },
  },
});

export default theme;
