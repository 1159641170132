import React from 'react';
import { TextInput, SimpleForm, Notification, required } from 'react-admin';
import FormLayout from 'components/FormLayout';
import FormToolbar from 'components/FormToolbar';
import useLogin from './useLogin';

interface LoginFormProps {
  redirectTo?: string;
}

/**
 * Login Form.
 */
const LoginForm = ({ redirectTo }: LoginFormProps) => {
  const { submit, loading } = useLogin({ redirectTo });
  return (
    <FormLayout>
      <SimpleForm
        variant="standard"
        save={submit}
        saving={loading}
        toolbar={<FormToolbar label="Login" />}
      >
        <TextInput
          isRequired
          validate={[required()]}
          label="Email"
          type="email"
          source="username"
        />
        <TextInput
          isRequired
          validate={[required()]}
          label="Password"
          type="password"
          source="password"
        />
      </SimpleForm>
      <Notification />
    </FormLayout>
  );
};

export default LoginForm;
