import {
  GetListParams,
  GetListResult,
  GetManyParams,
  GetManyResult,
  GetManyReferenceParams,
  GetManyReferenceResult,
  GetOneParams,
  GetOneResult,
  UpdateParams,
  UpdateResult,
  UpdateManyParams,
  CreateParams,
  CreateResult,
  DeleteResult,
  DeleteParams,
  DeleteManyParams,
  DeleteManyResult,
  UpdateManyResult,
  fetchUtils,
} from 'react-admin';
import { stringify } from 'query-string';
import config from 'config';
import { resources } from 'appConstants';
import { Auth } from 'aws-amplify';
import URITemplate from 'urijs/src/URITemplate';
import omit from 'lodash/omit';

const request = async (url: string, options: fetchUtils.Options = {}) => {
  const session = await Auth.currentSession();
  if (!options.headers) {
    options.headers = new Headers({
      Accept: 'application/json',
    });
  }

  if (options.headers instanceof Headers) {
    options.headers.set('Authorization', `Bearer ${ session.getAccessToken().getJwtToken() }`);
  } else {
    (options.headers as Record<string, any>)['Authorization'] = `Bearer ${ session.getAccessToken().getJwtToken() }`;
  }

  return fetch(config.baseUrl + url, {
    ...options,
    mode: 'cors',
    credentials: 'same-origin',

  })
    .then(async (response) => {
      const json = await response.json();
      return {
        status: response.status,
        result: json,
      }
    });
};

const getApiEndpointForResource = (resource: string, hasFilter: boolean = false) => {
    if (resource === resources.DOCUMENTS && hasFilter) {
      return '/search';
    }
    return {
      [resources.DOCUMENTS]: '/documents',
      [resources.BINARY]: '/bin',
      [resources.NOTES]: new URITemplate('/documents/{uuid}/notes'),
      [resources.HISTORY]: new URITemplate('/documents/{uuid}/history'),
      [resources.ATTACHMENTS]: new URITemplate('/documents/{uuid}/attachments'),
    }[resource]
};

const getParts = (resource: string) => {
  if (resource === resources.NOTES || resource === resources.HISTORY) {
    return [
      'uuid',
    ];
  }
  return [];
}

/**
 * Service Provider that implements interaction with the backend side.
 */
class ServiceProvider {
  getList<RecordType extends Record<string, any> = Record<string, any>>(
    resource: string,
    params: GetListParams,
  ): Promise<GetListResult<RecordType>> {
    const {
      filter,
      pagination: { page, perPage },
    } = params;
    const uri = getApiEndpointForResource(resource, Object.keys(filter).length !== 0);
    const parts = getParts(resource);
    const query = {
      page,
      size: perPage,
      ...omit(filter, parts),
      ...Object.keys(omit(filter, parts)).reduce((prevFilters, filterName) => {
        return {
          ...prevFilters,
          [filterName]: filter[filterName],
        };
      }, {}),
    };

    return request(
      `${uri instanceof URITemplate ? uri.expand(filter) : uri}?${stringify(query)}`,
      {
        method: 'GET',
      },
    ).then(({ result, status }) => {
      if (status === 404) {
        return {
          data: [],
          total: 0,
        }
      }
      return {
        data: result.items,
        total: result.total,
      } as GetListResult<RecordType>;
    });
  }
  getOne<RecordType extends Record<string, any> = Record<string, any>>(
    resource: string,
    params: GetOneParams,
  ): Promise<GetOneResult<RecordType>> {
    return request(
      `${getApiEndpointForResource(resource)}/${params.id}`,
      {
        method: 'GET',
      },
    ).then(({ result }) => {
      if (resource === resources.BINARY) {
        return {
          data: ({
            id: params.id,
            location: result.location,
          } as unknown as RecordType),
        };
      }
      return { data: result };
    });
  }

  getMany<RecordType extends Record<string, any> = Record<string, any>>(
    resource: string,
    params: GetManyParams,
  ): Promise<GetManyResult<RecordType>> {
    return Promise.reject();
  }

  getManyReference<
    RecordType extends Record<string, any> = Record<string, any>
    >(
    resource: string,
    params: GetManyReferenceParams,
  ): Promise<GetManyReferenceResult<RecordType>> {
    return Promise.reject();
  }

  update<RecordType extends Record<string, any> = Record<string, any>>(
    resource: string,
    params: UpdateParams,
  ): Promise<UpdateResult<RecordType>> {
    return Promise.reject();
  }

  updateMany<RecordType extends Record<string, any> = Record<string, any>>(
    resource: string,
    params: UpdateManyParams<Partial<RecordType>>,
  ): Promise<UpdateManyResult> {
    return Promise.reject();
  }

  create<RecordType extends Record<string, any> = Record<string, any>>(
    resource: string,
    params: CreateParams,
  ): Promise<CreateResult<RecordType>> {
    const uri = getApiEndpointForResource(resource);
    const parts = getParts(resource);
    if (resource === resources.ATTACHMENTS) {
      let formData = new FormData();
      formData.append('files', params.data.files.rawFile);
      return request(
        // @ts-ignore
        uri.expand(params.data) as string,
        {
          method: 'POST',
          body: formData,
        }
      ).then(({ result }) => ({
        data: {
          id: result.MessageId,
          ...result,
        },
      }));
    }
    return request(
      (uri instanceof URITemplate ? uri.expand(params.data) : uri) as string,
      {
        method: 'POST',
        body: JSON.stringify(omit(params.data, parts)),
      }
    ).then(({ result }) => ({
      data: {
        id: result.MessageId,
        ...result,
      },
    }));
  }

  delete<RecordType extends Record<string, any> = Record<string, any>>(
    resource: string,
    params: DeleteParams,
  ): Promise<DeleteResult<RecordType>> {
    return Promise.reject();
  }

  deleteMany(
    resource: string,
    params: DeleteManyParams,
  ): Promise<DeleteManyResult> {
    return Promise.reject();
  }
}

export default ServiceProvider;
