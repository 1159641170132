import React from 'react';
import {
  ListBase,
  ListToolbar,
  BulkActionsToolbar,
  Pagination,
  ListViewProps
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import MinimizeIcon from '@material-ui/icons/Minimize';
import DrawerProvider from 'components/Drawer';
import DocumentFilterForm from './DocumentFilterForm';

export interface ExtendedListViewProps extends ListViewProps {
  bottomChildren?: React.ReactNode;
}


const useStyles = makeStyles(() => ({
  card: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  container: {
    height: 'calc(100vh - 80px)',
    overflow: 'hidden',
  },
  collapseRoot: {
    height: '100%',
  },
  content: {
    padding: 0,
    overflowY: 'auto',
    flex: 1
  },
  tableContainer: {
    flex: (
      props: {
        hasBottomChildren: boolean;
        collapsed: boolean;
      }
    ) => props.collapsed
         ? '0 0 32px'
         : props.hasBottomChildren ? '0 0 50%' : '1 1 auto',
    overflow: 'hidden',
    paddingBottom: 0,
  },
  overflowY: {
    overflowY: 'auto',
  },
}));

const ListView = ({ children, bottomChildren, actions, bulkActionButtons, filters, title, ...props }: ExtendedListViewProps) => {
  const [collapsed, setCollapsed] = React.useState<boolean>(false);
  const classes = useStyles({ hasBottomChildren: !!bottomChildren, collapsed });
  const collapse = React.useCallback(() => {
    setCollapsed((prevState) => !prevState);
  }, [setCollapsed]);
  return (
    <DrawerProvider >
      <ListBase {...props}>
        <Grid
          container
          direction="column"
          wrap="nowrap"
          className={classes.container}
        >
          <Grid item container className={classes.tableContainer}>
            <ListToolbar
              filters={filters}
              actions={actions}
            />
            <Card className={classes.card}>
              <CardHeader
                title={collapsed ? null : <DocumentFilterForm />}
                action={<IconButton
                  size="small"
                  aria-label="attachments"
                  onClick={collapse}
                >
                  <Tooltip title="Minimize" aria-label="minimize">
                    <MinimizeIcon />
                  </Tooltip>
                </IconButton>}
              />
              <Collapse
                unmountOnExit
                in={!collapsed}
                timeout="auto"
                classes={{
                  root: classes.collapseRoot,
                  wrapper: classes.card,
                  wrapperInner: classes.card
                }}
              >
                <CardContent className={classes.content}>
                  <BulkActionsToolbar>
                    {bulkActionButtons}
                  </BulkActionsToolbar>
                  {React.cloneElement(children, {
                    hasBulkActions: bulkActionButtons !== false,
                  })}
                </CardContent>
                <CardActions style={{ padding: 0, width: '100%' }}>
                  <Pagination rowsPerPageOptions={[50]} />
                </CardActions>
              </Collapse>
            </Card>
          </Grid>
          {bottomChildren && (
            <Box flex={collapsed ? '1 1 100%' : '0 0 50%'} overflow="hidden">
              {bottomChildren}
            </Box>
          )}
        </Grid>
      </ListBase>
    </DrawerProvider>
  );
}

export default ListView;
