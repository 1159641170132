import React from 'react';
import { useAuthProvider, useNotify, useRedirect } from 'react-admin';
import { FormValues } from './types';

/**
 * Hook that updates an user's password.
 */
const useUpdatePassword = () => {
  const authProvider = useAuthProvider();
  const notify = useNotify();
  const redirect = useRedirect();
  const updatePassword = React.useCallback(
    async (values: FormValues) => {
      console.log('submit', values);
      try {
        await authProvider.changePassword(
          values.old_password,
          values.new_password,
        );
        redirect('/');
        notify('Password was changed.', 'success');
      } catch (error) {
        notify(
          error.message
            ? error.message
            : 'Something goes wrong, please check old password and new password.',
          'error',
        );
      }
    },
    [authProvider, notify, redirect],
  );

  return {
    updatePassword,
  };
};

export default useUpdatePassword;
