/**
 * Contains all resources that backend currently has.
 */

const DOCUMENTS = 'documents';
const BINARY = 'binary';
const NOTES = 'notes';
const HISTORY = 'history';
const ATTACHMENTS = 'attachments';

export const resources = {
  DOCUMENTS,
  BINARY,
  NOTES,
  HISTORY,
  ATTACHMENTS,
};

