import React from 'react';
import omit from 'lodash/omit';
import { Datagrid, DateField, TextField, ListProps, RowClickFunction } from 'react-admin';
import { Document } from 'types';
import ListView from 'components/ListView';
import Documents from './Documents';

/**
 * Document List page that allows to see jobs that match to filter criteria.
 */
const DocumentList = (props: ListProps) => {
  const [selectedDocuments, setSelectedDocuments] = React.useState<Record<number, Document>>({});
  const selectDocument = React.useCallback((id: number, basePath: string, record: Document) => {
      setSelectedDocuments((currentDocuments) => {
        if (currentDocuments[record.id]) {
          return {
            ...omit(currentDocuments, [record.id])
          }
        }
        return {
          ...currentDocuments,
          [record.id]: record,
        };
      });
      if (basePath) return basePath;
    },
    [setSelectedDocuments],
  );

  const close = React.useCallback((closedDocumentId: number) => {
    setSelectedDocuments((currentDocuments: Record<number, Document>) => {
      return Object.fromEntries(
        Object.entries(currentDocuments as Record<number, Document>).filter(
          ([key, value]) => closedDocumentId.toString() !== key.toString()
        ),
      );
    });
  }, [setSelectedDocuments]);

  const closeDocuments = React.useCallback(() => {
    setSelectedDocuments({});
  }, [setSelectedDocuments])
  return (
    <ListView
      {...props}
      perPage={50}
      bulkActionButtons={false}
      exporter={false}
      bottomChildren={
        <Documents
          documents={Object.values(selectedDocuments)}
          closeDocument={close}
          closeDocuments={closeDocuments}
        />
      }
    >
      <Datagrid rowClick={selectDocument as RowClickFunction}>
        <TextField sortable={false} label="Name" source="document_name" />
        <DateField showTime sortable={false} label="Date" source="document_date" />
        <TextField sortable={false} label="Link Id" source="link_id" />
      </Datagrid>
    </ListView>
  );
}

export default DocumentList;
