import React from 'react';
import { Identifier } from 'react-admin';
import Card from '@material-ui/core/Card';
import { makeStyles } from '@material-ui/core/styles';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import TimelineRoundedIcon from '@material-ui/icons/TimelineRounded';
import CommentRoundedIcon from '@material-ui/icons/CommentRounded';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import CloseIcon from '@material-ui/icons/Close';
import MinimizeIcon from '@material-ui/icons/Minimize';
import { Document } from 'types';

import History from './History';
import Notes from './Notes';
import Attachments from './Attachments';
import PdfViewer from './PdfViewer';

import useDrawer from 'components/Drawer/useDrawer';
import Collapse from '@material-ui/core/Collapse';

interface DocumentPanelProps {
  id?: Identifier;
  record: Document;
  resource?: string;
  close: (documentId: number) => void;
  collapsed: boolean;
  collapse: (documentId: number) => void;
}

const useStyles = makeStyles((theme) => ({
  card: {
    marginBottom: theme.spacing(3),
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  cardHeaderAction: {
    alignSelf: 'center',
  },
  cardHeaderTitle: {
    fontSize: 16,
  },
}));

const DocumentPanel = ({ record, close, collapse, collapsed }: DocumentPanelProps) => {
  const { open, close: closeDrawer } = useDrawer();
  const classes = useStyles();
  const showNotes = React.useCallback(() => {
    open(`${record.id}`, 'Notes', <Notes key={record.id} record={record} />);
  }, [open, record]);
  const showHistory = React.useCallback(() => {
    open(`${record.id}`, 'History', <History key={record.id} record={record} />);
  }, [open, record]);
  const showAttachments = React.useCallback(() => {
    open(`${record.id}`, 'Attachments', <Attachments key={record.id} record={record} />);
  }, [open, record]);
  const collapseDocument = React.useCallback(() => {
    collapse(record.id)
  }, [collapse, record])
  const closeDocument = React.useCallback(() => {
    closeDrawer(`${record.id}`);
    close(record.id);
  }, [close, record, closeDrawer])
  return (
    <Card square className={classes.card}>
      <CardHeader
        classes={{
          action: classes.cardHeaderAction,
          title: classes.cardHeaderTitle,
        }}
        action={
          <React.Fragment>
            {
              !collapsed && (
                <React.Fragment>
                  <Tooltip title="Notes" aria-label="notes">
                    <IconButton
                      size="small"
                      aria-label="notes"
                      onClick={showNotes}
                    >
                      <CommentRoundedIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="History" aria-label="history">
                    <IconButton
                      size="small"
                      aria-label="history"
                      onClick={showHistory}
                    >
                      <TimelineRoundedIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Attachments" aria-label="attachments">
                    <IconButton
                      size="small"
                      aria-label="attachments"
                      onClick={showAttachments}
                    >
                      <AttachFileIcon />
                    </IconButton>
                  </Tooltip>
                </React.Fragment>
              )
            }
            <Tooltip title="Collapse/Expand" aria-label="collapse/expand">
              <IconButton
                size="small"
                aria-label="attachments"
                onClick={collapseDocument}
              >
                <MinimizeIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Close" aria-label="close">
              <IconButton
                size="small"
                aria-label="close"
                onClick={closeDocument}
              >
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </React.Fragment>
        }
        title={record?.document_name}
      />
      <Collapse in={!collapsed} timeout="auto" unmountOnExit>
        <CardContent>
          <PdfViewer record={record} />
        </CardContent>
      </Collapse>
    </Card>
  );
};

export default DocumentPanel;
