import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Admin, DataProvider, Resource } from 'react-admin';
import { createBrowserHistory as createHistory } from 'history';
import { resources } from 'appConstants';
import { AuthProvider, ServiceProvider } from 'providers';
import { documents } from 'resources';
import routes from 'routes';
import { Login } from 'pages';
import theme from 'theme';

const App = () => (
  <React.Fragment>
    <CssBaseline />
    <Admin
      disableTelemetry
      loginPage={Login}
      // layout={Layout}
      // dashboard={Dashboard}
      theme={theme}
      history={createHistory()}
      customRoutes={routes}
      dataProvider={new ServiceProvider() as DataProvider}
      authProvider={new AuthProvider()}
    >
      <Resource name={resources.DOCUMENTS} {...documents} />
    </Admin>
  </React.Fragment>
);

export default App;
