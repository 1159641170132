import React from 'react';
import { useAuthProvider, useNotify, useRedirect } from 'react-admin';
import { FormValues } from './types';

/**
 * Hook that register a new user.
 */
const useUpdatePassword = () => {
  const authProvider = useAuthProvider();
  const notify = useNotify();
  const redirect = useRedirect();
  const singUp = React.useCallback(
    async (values: FormValues) => {
      try {
        const { userConfirmed } = await authProvider.signUp(
          values.email,
          values.password,
          values.name,
        );
        if (userConfirmed) {
          redirect('/login');
          notify('Your were successfully registered.', 'success');
          return;
        }
        redirect('/confirm');
        notify('Please check your email and approve your identity.', 'warning');
      } catch (error) {
        notify(
          error.message
            ? error.message
            : 'Something goes wrong, please check username and password.',
          'error',
        );
      }
    },
    [authProvider, notify, redirect],
  );

  return {
    singUp,
  };
};

export default useUpdatePassword;
