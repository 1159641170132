import React from 'react';
import { Document as PDFDocument, Page, pdfjs } from 'react-pdf';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import { Document } from 'types';
import LoadingWrapper from 'components/LoadingWrapper';
import useDocumentBinary from './useDocumentBinary';


export interface PdfViewerProps {
  record: Document;
}

const useStyles = makeStyles(() => ({
  document: {
    height: '60vh',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  container: {
    height: '60vh',
  },
}));

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PdfViewer = ({ record }: PdfViewerProps) => {
  const { document, loading, numPages, onDocumentLoadSuccess } = useDocumentBinary({ record });
  const classes = useStyles();
  return (
    <Grid
      item
      container
      justifyContent="center"
      className={classes.container}
    >
      <LoadingWrapper loading={loading}>
        {document && document.location && (
          <PDFDocument
            className={classes.document}
            file={document.location}
            loading={() => <CircularProgress />}
            onLoadSuccess={onDocumentLoadSuccess}
            options={{
              cMapUrl: `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/cmaps/`,
              cMapPacked: true,
            }}
          >
            {
              Array.from(
                new Array(numPages),
                (el, index) => (
                  <Page
                    key={`page_${index + 1}`}
                    pageNumber={index + 1}
                  />
                ),
              )
            }
          </PDFDocument>
        )}
      </LoadingWrapper>
    </Grid>
  )
};

export default PdfViewer;
