import React from 'react';
import { useDataProvider } from 'react-admin';
import { resources } from 'appConstants';
import { Document } from 'types';

interface UseGetBinaryProps {
  record?: Document;
}

const useDocumentBinary = ({ record }: UseGetBinaryProps) => {
  const dataProvider = useDataProvider();
  const [document, setDocument] = React.useState<Record<string, any>>();
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState();
  const [numPages, setNumPages] = React.useState<number>(0);
  
  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  }
  
  React.useEffect(() => {
    if (!record || !loading) return;
    dataProvider.getOne(resources.BINARY, { id: record.docid })
      .then(({ data }) => {
        setDocument(data);
        setLoading(false);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      })
  }, [dataProvider, record, loading, setDocument, setLoading, setError]);
  
  return {
    document,
    loading,
    error,
    numPages,
    onDocumentLoadSuccess,
  }
}

export default useDocumentBinary;
