import React from 'react';
import { useDataProvider } from 'react-admin';
import { resources } from 'appConstants';
import { Document, History } from 'types';

interface UseHistoryProps {
  record?: Document;
}

const useHistory = ({ record }: UseHistoryProps) => {
  const dataProvider = useDataProvider();
  const once = React.useRef(false);
  const [history, setHistory] = React.useState<History[]>([]);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState();

  const refresh = React.useCallback(() => {
    if (!record) return;
    setLoading(true);
    dataProvider.getList<History>(
      resources.HISTORY,
      {
        filter: {
          uuid: record.docid,
        },
        sort: {
          field: 'id',
          order: ''
        },
        pagination: {
          page: 1,
          perPage: 50,
        },
      })
      .then(({ data }) => {
        setHistory(data);
        setLoading(false);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      })
  }, [dataProvider, record, setHistory, setLoading, setError]);

  React.useEffect(() => {
    if (!record || once.current) return;

    once.current = true;
    refresh();
  }, [record, refresh, once]);

  return {
    refresh,
    loading,
    error,
    history
  }
}

export default useHistory;
