import React from 'react';
import { Route } from 'react-router-dom';
import { Authenticated } from 'react-admin';
import {
  SignUp,
  ChangePassword,
  ConfirmSignUp,
  ForgotPassword,
  ResetPassword,
} from 'pages';

/**
 * Additional routes.
 */
const routes = [
  <Route exact path="/change-password">
    <Authenticated>
      <ChangePassword />
    </Authenticated>
  </Route>,
  // @ts-ignore
  <Route exact noLayout path="/sign-up">
    <SignUp />
  </Route>,
  // @ts-ignore
  <Route exact noLayout path="/confirm">
    <ConfirmSignUp />
  </Route>,
  // @ts-ignore
  <Route exact noLayout path="/forgot-password">
    <ForgotPassword />
  </Route>,
  // @ts-ignore
  <Route exact noLayout path="/reset-password">
    <ResetPassword />
  </Route>,
];

export default routes;
