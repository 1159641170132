import React from 'react';
import format from 'date-fns/format'
import Grid from '@material-ui/core/Grid';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import Typography from '@material-ui/core/Typography';
import { Document, History as HistoryType, HistoryTypeEnum } from 'types';
import LoadingWrapper from 'components/LoadingWrapper';
import useHistory from './useHistory';

export interface HistoryProps {
  record: Document
}

const formatHistory = (history: HistoryType): string => {
  const humanReadableType = {
    [HistoryTypeEnum.Read]: 'read',
    [HistoryTypeEnum.Create]: 'created',
    [HistoryTypeEnum.Delete]: 'deleted',
    [HistoryTypeEnum.Update]: 'updated',
  }[history.histtype];
  return `${history.histcat} was ${humanReadableType}`
}

const History = ({ record }: HistoryProps) => {
  const { history, loading } = useHistory({ record });
  return (
    <Grid
      item
      container
      direction="column"
    >
      <LoadingWrapper loading={loading}>
        <Timeline align="left">
          {history.map((history) => (
            <TimelineItem key={history.id}>
              <TimelineOppositeContent>
                <Typography color="textSecondary">
                  {format(new Date(history.created_at), 'PP, pp')}
                </Typography>
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <Typography>{formatHistory(history)}</Typography>
              </TimelineContent>
            </TimelineItem>
          ))}
        </Timeline>
      </LoadingWrapper>
    </Grid>
  )
}

export default History;
