import React, { MouseEvent } from 'react';


export interface DrawerContextProps {
  open: (id: string, title: string, content: React.ReactNode) => void;
  close: (id?: string | MouseEvent<HTMLButtonElement>) => void;
}

const DrawerContext = React.createContext<DrawerContextProps>({
  open: () => null,
  close: () => null,
});

export default DrawerContext;
