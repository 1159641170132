import List from './List';

/**
 * Configuration for document resource.
 */
const documents = {
  list: List,
  options: {
    label: 'Document',
  },
};

export default documents;
