import React, { MouseEvent } from 'react';
import Drawer from '@material-ui/core/Drawer';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import Close from '@material-ui/icons/Close';
import DrawerContext from './context';

export interface DrawerProviderProps {
  children: React.ReactNode;
}

interface MakeStylesProps {
  showDrawer: boolean;
}

const useStyles = makeStyles((theme) => ({
  container: {
    paddingRight: (props: MakeStylesProps) => props.showDrawer ? '20vw' : 0,
  },
  drawer: {
    top: 56,
    width: '20vw',
    padding: 0,
    height: 'calc(100% - 48px)',
    borderLeft: 'none',
  },
  drawerContainer: {
    height: '100%',
  },
  overflowY: {
    overflowY: 'auto',
    height: '100%',
    padding: theme.spacing(1),
  },
  header: {
    padding: theme.spacing(1),
    backgroundColor: '#3a3633',
    color: '#fff',
  },
  closeIcon: {
    color: '#fff',
  },
}));

const DrawerProvider = ({ children }: DrawerProviderProps) => {
  const [drawerState, setDrawerState] = React.useState({
    id: '',
    title: '',
    content: null,
    isOpen: false,
  });
  
  const open = React.useCallback((id, title, content) => {
    setDrawerState({
      id,
      title,
      content,
      isOpen: true,
    })
  }, [setDrawerState]);
  
  const close = React.useCallback((id?: string | MouseEvent<HTMLButtonElement>) => {
    setDrawerState((prev) => {
      const empty = {
        id: '',
        title: '',
        content: null,
        isOpen: false,
      };
      if (typeof id === 'string' && id === prev.id) {
        return empty;
      } else if (typeof id !== 'string') {
        return empty;
      }
      return prev;
    });
  }, [setDrawerState]);
  
  const classes = useStyles({ showDrawer: drawerState.isOpen });
  
  return (
    <DrawerContext.Provider value={{ open, close }}>
      <Grid
        container
        direction="column"
        wrap="nowrap"
        className={classes.container}
      >
        {children}
      </Grid>
      <Drawer
        variant="persistent"
        anchor="right"
        open={drawerState.isOpen}
        PaperProps={{
          className: classes.drawer,
        }}
      >
        <Grid
          container
          direction="column"
          wrap="nowrap"
          className={classes.drawerContainer}
        >
          <Grid
            item
            container
            xs="auto"
            justifyContent="space-between"
            alignItems="center"
            className={classes.header}
          >
            <Grid item xs="auto">
              <Typography gutterBottom>{drawerState.title}</Typography>
            </Grid>
            <Grid item xs="auto">
              <Tooltip title="Close" aria-label="close">
                <IconButton
                  size="small"
                  aria-label="close"
                  className={classes.closeIcon}
                  onClick={close}
                >
                  <Close />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
          <Grid container className={classes.overflowY}>
            {drawerState.content}
          </Grid>
        </Grid>
      </Drawer>
    </DrawerContext.Provider>
  )
}

export default DrawerProvider;
