import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import DownloadIcon from '@material-ui/icons/GetApp';
import { Document } from 'types';
import LoadingWrapper from 'components/LoadingWrapper';
import useAttachments from './useAttachments';
import AttachmentForm from 'resources/documents/List/Documents/Document/AttachmentForm';
import IconButton from '@material-ui/core/IconButton';

export interface AttachmentsProps {
  record: Document;
}

const Attachments = ({ record }: AttachmentsProps) => {
  const { attachments, loading, refresh, version, download } = useAttachments({ record });
  return (
    <Grid
      item
      container
      direction="column"
    >
      <AttachmentForm key={version} record={record} onSuccess={refresh} />
      <LoadingWrapper loading={loading}>
        {attachments.map((attachment) => (
          <Typography key={attachment.id}>
            {attachment.attid}
            <IconButton color="primary" onClick={download(attachment)}>
              <DownloadIcon />
            </IconButton>
          </Typography>
        ))}
      </LoadingWrapper>
    </Grid>
  )
}

export default Attachments;
