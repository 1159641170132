import useDrawer from 'components/Drawer/useDrawer';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import MinimizeIcon from '@material-ui/icons/Minimize';
import AllOutIcon from '@material-ui/icons/AllOut';
import { Document } from 'types';
import DocumentPanel from './Document';

export interface DocumentsProps {
  documents: Document[];
  closeDocument: (documentId: number) => void;
  closeDocuments: () => void;
}

const useStyles = makeStyles((theme) => ({
  card: {
    marginTop: theme.spacing(3),
    overflow: 'hidden',
    height: '100%',
  },
  container: {
    overflowY: 'auto',
    padding: theme.spacing(1, 0),
    height: 'calc(100% - 82px)',
  }
}));

const Documents = ({ documents, closeDocument, closeDocuments: onCloseDocuments }: DocumentsProps) => {
  const classes = useStyles();
  const { close } = useDrawer();
  const [collapsedDocs, setCollapsedDocs] = React.useState<Record<number, boolean>>({});
  const collapse = React.useCallback((documentId) => {
    setCollapsedDocs((prevState) => ({
      ...prevState,
      [documentId]: !prevState[documentId],
    }))
  }, [setCollapsedDocs]);
  const expandAll = React.useCallback(() => {
    setCollapsedDocs(documents.reduce((prev, document) => ({
      ...prev,
      [document.id]: false,
    }), {}))
  }, [setCollapsedDocs, documents]);
  const collapseAll = React.useCallback(() => {
    setCollapsedDocs(documents.reduce((prev, document) => ({
      ...prev,
      [document.id]: true,
    }), {}))
  }, [setCollapsedDocs, documents]);
  const closeDocuments = React.useCallback(() => {
    close();
    onCloseDocuments();
  }, [close, onCloseDocuments]);
  return (
    <Card className={classes.card}>
      <CardHeader
        action={
          <React.Fragment>
            <Tooltip title="Minimise all" aria-label="minimize all">
              <IconButton size="small" aria-label="minimize all" onClick={collapseAll}>
                <MinimizeIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Expand all" aria-label="expand all">
              <IconButton size="small" aria-label="expand" onClick={expandAll}>
                <AllOutIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Close all" aria-label="close all">
              <IconButton size="small" aria-label="close all" onClick={closeDocuments}>
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </React.Fragment>
        }
        title="Documents"
      />
      <CardContent className={classes.container}>
        {documents.length ? documents.map((document) => (
          <DocumentPanel
            key={document.id}
            record={document}
            close={closeDocument}
            collapse={collapse}
            collapsed={collapsedDocs[document.id]}
          />
        )) : (
          <Typography align="center">No documents selected</Typography>
        )}
      </CardContent>
    </Card>
  )
}

export default Documents;
