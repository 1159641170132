import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

export interface LoadingWrapperProps {
  loading: boolean;
  children: React.ReactNode;
}

const useStyles = makeStyles(() => ({
  container: {
    height: '100%',
  },
}));


const LoadingWrapper = ({ loading, children }: LoadingWrapperProps) => {
  const classes = useStyles();
  return (
    <React.Fragment>
      {loading ? (
        <Grid xs item container justifyContent="center" className={classes.container}>
          <CircularProgress />
        </Grid>
      ) : (
         children
       )}
    </React.Fragment>
  );
}

export default LoadingWrapper;
