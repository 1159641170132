import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import { Filter, TextInput, useListController } from 'react-admin';
import { resources } from 'appConstants';

const useStyles = makeStyles(() => ({
  filterForm: {
    minHeight: 55,
    flex: '1 1 auto',
  },
  label: {
    display: 'none',
  },
  input: {
    width: '100%',
    margin: 0,
    color: '#fff',
  }
}));

const DocumentFilterForm = () => {
  const context = useListController({
    resource: resources.DOCUMENTS,
    basePath: '/documents',
    perPage: 50,
    syncWithLocation: true,
  });
  const classes = useStyles()
  return (
    <Filter {...context} classes={{ form: classes.filterForm }}>
      <TextInput
        fullWidth
        alwaysOn
        label={false}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        className={classes.input}
        variant="standard"
        source="q"
      />
    </Filter>
  );
};

export default DocumentFilterForm;
