import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { SimpleForm, FileInput, FileField, Create, CreateProps, required } from 'react-admin';
import { Document } from 'types';
import { resources } from 'appConstants';
import FormToolbar from 'components/FormToolbar';

interface NoteFormProps {
  record: Document;
  onSuccess?: CreateProps['onSuccess'];
}

const useStyles = makeStyles((theme: Theme) => ({
  form: {
    marginBottom: theme.spacing(1),
  }
}));

const NoteForm = ({ record, onSuccess }: NoteFormProps) => {
  const classes = useStyles();
  const transform = React.useCallback((data) => {
    return {
      ...data,
      document_id: record.id,
      uuid: record.docid,
    }
  }, [record]);
  return (
    <Create
      className={classes.form}
      resource={resources.ATTACHMENTS}
      basePath={`/${resources.DOCUMENTS}`}
      transform={transform}
      onSuccess={onSuccess}
      title=" "
    >
      <SimpleForm
        variant="standard"
        toolbar={<FormToolbar label="Upload" />}
      >
        <FileInput isRequired fullWidth validate={required()} source="files" label={false}>
          <FileField source="src" title="title" />
        </FileInput>
      </SimpleForm>
    </Create>
  );
}

export default NoteForm;
