import React from 'react';
import { useDataProvider } from 'react-admin';
import { resources } from 'appConstants';
import { Document, Note } from 'types';

interface UseNotesProps {
  record?: Document;
}

const useNotes = ({ record }: UseNotesProps) => {
  const dataProvider = useDataProvider();
  const once = React.useRef(false);
  const [notes, setNotes] = React.useState<Note[]>([]);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState();

  const refresh = React.useCallback(() => {
    if (!record) return;
    setLoading(true);
    dataProvider.getList<Note>(
      resources.NOTES,
      {
        filter: {
          uuid: record.docid,
        },
        sort: {
          field: 'id',
          order: ''
        },
        pagination: {
          page: 1,
          perPage: 50,
        },
      })
      .then(({ data }) => {
        setNotes(data);
        setLoading(false);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      })
  }, [dataProvider, record, setNotes, setLoading, setError]);

  React.useEffect(() => {
    if (!record || once.current) return;

    once.current = true;
    refresh();
  }, [record, refresh, once]);

  return {
    loading,
    error,
    notes,
    refresh,
  }
}

export default useNotes;
