export interface Document {
  archive_id: null | number;
  author: string;
  created_at: string;
  description: string;
  doc_section: string;
  doc_status: string;
  doc_type: string;
  docid: string;
  document_date: string;
  document_name: string;
  document_reference_id: string;
  env_id: string;
  id: number;
  insurance_nexus_application: string;
  link_id: string;
  linked_to: string;
  mime_type: string;
  sub: string;
}

export interface Note {
  id: number;
  created_at: string;
  document_id: number;
  notedata: string;
  sub: string;
  user_id: string | null;
}

export enum HistoryCategoryEnum {
  Document = 'Document',
  Note = 'Note',
  Attachment = 'Attachment',
}

export enum HistoryTypeEnum {
  Create = 'Create',
  Read = 'Read',
  Update = 'Update',
  Delete = 'Delete',
}

export interface History {
  id: number;
  sub: string;
  document_id: number;
  user_id: string;
  histcat: HistoryCategoryEnum;
  histtype: HistoryTypeEnum;
  histmeta: string;
  created_at: string;
}

export interface Attachment {
  id: number;
  attid: string;
  created_at: string;
  document_id: number;
  sub: string;
  user_id: string;
}
