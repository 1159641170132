import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { required, SimpleForm, TextInput, Create, CreateProps } from 'react-admin';
import { Document } from 'types';
import { resources } from 'appConstants';
import FormToolbar from 'components/FormToolbar';

interface NoteFormProps {
  record: Document;
  onSuccess?: CreateProps['onSuccess'];
}

const useStyles = makeStyles((theme: Theme) => ({
  form: {
    marginBottom: theme.spacing(1),
  }
}));

const NoteForm = ({ record, onSuccess }: NoteFormProps) => {
  const classes = useStyles();
  const transform = React.useCallback((data) => {
    return {
      ...data,
      document_id: record.id,
      uuid: record.docid,
    }
  }, [record]);
  return (
    <Create
      className={classes.form}
      resource={resources.NOTES}
      basePath={`/${resources.DOCUMENTS}`}
      transform={transform}
      onSuccess={onSuccess}
      title=" "
    >
      <SimpleForm
        variant="standard"
        toolbar={<FormToolbar label="Post" />}
      >
        <TextInput
          isRequired
          validate={[required()]}
          label="Note"
          type="text"
          source="notedata"
        />
      </SimpleForm>
    </Create>
  );
}

export default NoteForm;
