import * as React from 'react';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import { defaultTheme } from 'react-admin';
// import theme from 'theme';
import { LoginForm } from './components';

/**
 * Login page.
 */
const Login = () => (
  <ThemeProvider theme={createTheme(defaultTheme)}>
    <LoginForm />
  </ThemeProvider>
);

export default Login;
